import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { WEAPONS } from "@/game-csgo/constants.mjs";
import { getSearchParams } from "@/game-csgo/csgo-fetch-stats-data.mjs";
import { getWeaponIcon } from "@/game-csgo/utils.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const COLS = [
  {
    i18n: ["common:weapon", "Weapon"],
    dataKey: "weaponId",
  },
  {
    i18n: ["common:killsPerRound", "Kills / Round"],
    dataKey: "kpr",
  },
  {
    i18n: ["common:headshotPercentage", "Headshot %"],
    dataKey: "headshotPercent",
  },
];

export default function HomeWeaponsTile() {
  const { searchParams } = useRoute();
  const { t } = useTranslation();
  const {
    csgo: {
      stats: { weapons },
    },
  } = useSnapshot(readState);
  const params = new URLSearchParams(getSearchParams(searchParams));
  const key = btoa(params.toString());

  const data = useMemo(() => {
    if (weapons[key] instanceof Error) return [];
    return Object.entries(weapons[key] || {})
      .map(([weaponId, stats]) => {
        const key = WEAPONS[weaponId]?.key;
        return {
          weaponId,
          link: key && `/cs2/database/weapons/${key}/overview`,
          kpr: stats.kills_per_round,
          adr: 0,
          matches: stats.num_matches,
          headshotPercent: stats["head_%"],
          shotsBreakdown: {
            headshotPercent: stats["head_%"] * 100,
            bodyshotPercent: stats["body_%"] * 100,
            legshotPercent: stats["leg_%"] * 100,
            stomachshotPercent: stats["stomach_%"] * 100,
          },
        };
      })
      .sort((a, b) => b.matches - a.matches);
  }, [key, weapons]);

  return (
    <GameGuideTileLg
      title={["common:navigation.weapons", "Weapons"]}
      description={[
        "home:guides.cs2.weapons.description",
        "Make every shot count with our CS2 weapon stats. Improve your accuracy, weapon choice, and performance to dominate your opponents.",
      ]}
      buttonText={["common:navigation.viewWeapons", "View Weapons"]}
      href={"/cs2/stats/weapons"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="wep-meta">{t(COLS[0].i18n)}</div>
          <div className="stat">{t(COLS[1].i18n)}</div>
          <div className="stat">{t(COLS[2].i18n)}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body>
          {(data || []).map((row, i) => (
            <GameGuideTable.Row key={i} link={row.link}>
              <div className={`wep-meta type-subtitle--bold`}>
                <img
                  src={getWeaponIcon(WEAPONS[row.weaponId]?.icon)}
                  width="400"
                  height="128"
                  className="wep-img"
                />
                {t(WEAPONS[row.weaponId]?.i18nKey, WEAPONS[row.weaponId]?.name)}
              </div>
              <div className="stat type-body2-form--bold">
                {row.kpr.toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}
              </div>
              <span className="stat type-body2-form--bold">
                {row.headshotPercent.toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </span>
            </GameGuideTable.Row>
          ))}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .wep-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .wep-img {
    width: 4.5rem;
    height: auto;
    aspect-ratio: 25 / 8;
    border-radius: var(--br-sm);
  }
  .wep-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 25%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
